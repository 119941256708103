<template>
  <div class="page">
    <div class="search_box">
      <div class="search">
        <van-button type="default" @click="showYearPopup"
          >{{ year }}
          <van-icon name="arrow-down" />
        </van-button>
        <van-button type="default" @click="showAreaPopup"
          >{{ course }}
          <van-icon name="arrow-down" />
        </van-button>
        <input type="number" v-model="score" class="input" />分
        <button class="btn" @click="clickSearch">查询</button>
      </div>
      <div class="f14" style="color: rgb(255, 255, 255);">
        <p class="mt10" style="">
          位次区间：{{ listData.highestRank }} ~ {{ listData.lowestRank }}
        </p>
        <p class="mt10" style="">同分人数：{{ listData.sameCount }}人</p>
        <p class="mt10">建议位次：{{ listData.lowestRank }}位</p>
        <p class="mt10">竞争指数：低</p>
      </div>
    </div>
    <van-popup
      round
      v-model="showArea"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <ul class="year">
        <li
          v-for="(item, index) in configData[areaIndex] &&
            configData[areaIndex].courses"
          :key="index"
          @click="handleArea(item, index)"
          :class="item.course == course ? 'active' : ''"
        >
          {{ item.course }}
          <van-icon name="success" v-if="item.course == course" />
        </li>
      </ul>
    </van-popup>
    <van-popup
      round
      v-model="showYear"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <ul class="year">
        <li
          v-for="(item, index) in configData"
          :key="index"
          @click="handleYear(item, index)"
          :class="item.year == year ? 'active' : ''"
        >
          {{ item.year }}
          <van-icon name="success" v-if="item.year == year" />
        </li>
      </ul>
    </van-popup>
    <!-- <div class="table">
      <div class="table_title">历年等位分</div>
      <el-table class="table_data" :data="listData" style="width: 100%">
        <el-table-column prop="year" label="年份"> </el-table-column>
        <el-table-column label="位次区间">
          <template slot-scope="scope">
            {{ scope.row.highestRank }} ~ {{ scope.row.lowestRank }}
          </template>
        </el-table-column>
        <el-table-column prop="score" label="等位分"> </el-table-column>
      </el-table>
    </div> -->
    <div class="table">
      <div class="table_title">{{ area }} {{ year }} {{ course }}分数段</div>
      <el-table class="table_data" :data="listData.yfyds" style="width: 100%">
        <el-table-column prop="year" label="年份"> </el-table-column>
        <el-table-column label="位次区间">
          <template slot-scope="scope">
            <span v-if="scope.row.highestRank == scope.row.lowestRank">
              {{ scope.row.highestRank }}
            </span>
            <span v-else>
              {{ scope.row.highestRank }} ~ {{ scope.row.lowestRank }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="sameCount" label="同分人数"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import noData from '@/assets/image/nodata.png';

export default {
  components: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      currentYear: (state) => state.currentYear,
      isNew: (state) => state.isNew,
    }),
  },
  data() {
    return {
      listData: [], //全部院校
      config: [],
      noData: noData,
      area: '',
      year: '2023',
      provinceId: null,
      courseData: [
        {
          id: 1,
          name: '物理',
        },
        {
          id: 2,
          name: '历史',
        },
      ],
      course: '理科',
      bzType: ' 本专',
      score: null,
      showArea: false,
      showYear: false,
      areaIndex: 0,
      configData: [],
    };
  },
  watch: {
    currentProvince: function(val) {
      console.log('currentProvince', val);
      // this.area = val;
    },
  },
  async mounted() {
    // await this.getEnrolldataConfig();
    // await this.getYfydConfig();
    // await this.getEnrolldata();
    // let local = JSON.parse(localStorage.getItem('localProvinceInfo'));
    // console.log('local', local);
    // this.area = local.name;
    // this.provinceId = local.id;
  },
  created() {
    this.getEnrolldataConfig();
    //  this.getYfydConfig();
    //  this.getEnrolldata();
  },
  methods: {
    //Area
    showAreaPopup() {
      this.showArea = true;
    },
    handleArea(item, index) {
      this.course = item.course;
      console.log('===handleArea', item);
      this.bzType = item.bzTypes[0];
      this.showArea = false;
      this.getEnrolldata();
    },
    //year
    showYearPopup() {
      this.showYear = true;
    },
    handleYear(item, index) {
      this.year = item.year;
      this.areaIndex = index;
      this.course = item.courses[0].course;
      this.bzType = item.courses[0].bzTypes[0];
      console.log('====', item);
      this.showYear = false;
      this.getEnrolldata();
    },
    clickSearch() {
      // this.listData=[]
      this.getEnrolldata();
    },
    //获取筛选列表
    async getEnrolldataConfig() {
      let apiUrl = `/php/enrolldata.config`;
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        if (res.data.ret == 0) {
          this.config = res.data.data;
          // this.area = this.userInfo.province;
          // this.config.forEach((ele, index) => {
          //   if (this.area && this.area == ele.provinceName) {
          //     this.provinceId = ele.provinceId;
          //     this.areaIndex = index;
          //   } else {
          //     this.provinceId = this.config[0].provinceId;
          //     this.area = this.config[0].provinceName;
          //   }
          // });
          let localProvinceInfo = JSON.parse(
            localStorage.getItem('localProvinceInfo')
          )|| [];

          this.config.find((ele, index) => {
            if (ele.provinceName == localProvinceInfo.name) {
              this.area = ele.provinceName;
              // this.areaIndex = index;
              this.provinceId = ele.provinceId;
              this.getYfydConfig();
            }
          });
        }
    },
    async getYfydConfig() {
      let apiUrl = `/php/yfyd.config?provinceId=${this.provinceId}`;
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res==getYfydConfig', res);
        if (res.data.ret == 0) {
          this.configData = res.data.data;
          console.log(
            '===this.configData[this.areaIndex].courses[0]',
            this.configData
          );
          if (this.configData.length > 0) {
            this.year = this.configData[0].year;
            this.course = this.configData[0].courses[0].course;
            this.bzType = this.configData[0].courses[0].bzTypes[0];

            await this.getEnrolldata();
          }
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //批次
    async getEnrolldata() {
      let year = this.year == '年份' ? '' : this.year;
      let apiUrl = `/php/yfyd.list?provinceId=${
        this.provinceId
      }&year=${year}&course=${this.course}&bzType=${this.bzType}&score=${
        this.score ? this.score : 0
      }`;
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        if (res.data.ret == 0) {
          this.listData = res.data.data;
        }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  min-height: 100vh;
  background: #fff;
  padding-top: 51px;
}
.search {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  height: 40px;
  position: relative;
  padding: 0;
  .van-button--default {
    color: #323233;
    background: none;
    border: none;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    margin-right: 8px;
  }
  .input {
    background: none;
    border: none;
    width: 50px;
    font-size: 14px;
  }
  .btn {
    position: absolute;
    right: 10px;
    background: #910000;
    color: #fff;
    border-radius: 3px;
    padding: 3px 10px;
  }
}
.year {
  padding: 20px 10px;
  font-size: 16px;
  color: #222;
  font-weight: 700;
  li {
    padding: 13px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.active {
  color: #910000;
}
.table {
  .table_title {
    font-size: 18px;
    padding: 10px 15px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    color: #212121;
  }
  .table_data {
    font-size: 12px;
    text-align: center;
  }
  .el-table--enable-row-transition .el-table__body td {
    // text-align: center;
    padding: 6px;
    color: #000;
  }
  .el-table--enable-row-transition .el-table__body td:nth-last-child(1) {
    text-align: center;
  }
  .el-table--enable-row-transition .el-table__body tr:nth-child(odd) td {
    background-color: #fafafa !important;
  }
  .el-table th {
    padding: 6px;
    color: #000;
  }
}
.no_data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  img {
    width: 180px;
  }
  .text {
    font-size: 16px;
    color: #666;
    text-align: center;
    width: 100%;
    font-weight: 600;
    padding-top: 20px;
  }
}
.el-message-box {
  width: 90%;
  .el-button {
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
  }
  .el-button--primary {
    background: #910000;
    border-color: #910000;
    color: #fff;
  }
}
.search_box {
  background: #910000;
  padding: 20px 15px 35px;
}
</style>
